<template>
    <ais-clear-refinements
        class="algolia-reset-btn"
        :excluded-attributes="excludedAttributes"
    >
        <template v-slot="{ refine, canRefine }">
            <HBtn
                v-if="customized || canRefine"
                style-type="primary"
                class="reset-btn"
                @click="refine"
            >{{ label }}</HBtn>
            <div v-else></div>
        </template>
    </ais-clear-refinements>
</template>

<script>
import {
    AisClearRefinements
} from 'vue-instantsearch'
import {
    HBtn
} from '@happytal/bo-ui-library'

export default {
    name: 'AlgoliaResetBtn',
    props: {
        label: {
            type: String,
            required: true
        },
        excludedAttributes: {
            type: Array
        },
        customized: {
            type: Boolean,
            default: false
        }
    },
    components: {
        AisClearRefinements,
        HBtn
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';
.algolia-reset-btn {
    padding: 20px 0px 20px 0px;
    text-align: center;

    .reset-btn {

    }
}
</style>
