<template>
    <ais-hierarchical-menu
        class="algolia-category-list-mask"
        :attributes="[
            'categories.level0',
            'categories.level1',
            'categories.level2',
            'categories.level3'
        ]"
    >
        <template v-slot:default>
            <div class="category-list"></div>
        </template>
    </ais-hierarchical-menu>
</template>

<script>
import {
    AisHierarchicalMenu
} from 'vue-instantsearch'

export default {
    name: 'AlgoliaCategoryListMask',
    components: {
        AisHierarchicalMenu
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';
.algolia-category-list-mask {

    .category-list {

    }
}
</style>
