<template>
    <div id="resident-catatog" class="resident-catalog">
        <ResidentHeader
            page-title="Catalogue"
            class="mb-4"
            :displayResidentMenu="!isMobile"
        />
        <CatalogItemHeader
            :breadcrumbItems="breadcrumbItems"
            :residentId="resident_id"
            :disableBasketButton="!isEditionAllowed"
            class="mb-4"
        />
        <SliderBanners
            v-if="canDisplayBanners"
            :banners="banners"
            class="mt-2 mb-4 mb-sm-6"
        />

        <HCard
            v-if="canEmitSuggestions"
            class="coordination-card d-flex flex-column mb-8 mt-4"
        >
            <div class="choice-text">
                Vous souhaitez suggérer au référent du résident un produit ou un service ?<br>
                Vous pouvez soit le rechercher dans le catalogue, soit faire une suggestion libre
            </div>
            <div class="d-flex flex-column flex-md-row justify-center align-center mt-4">
                <ConditionalTooltip
                    :enabled="isSuggestionDisabled()"
                    color="gray"
                >
                    <template #activator>
                        <HBtn
                            class="add-suggestion"
                            :disabled="isSuggestionDisabled()"
                            @click="onAddSuggestionBtnClick"
                            data-cy="add-suggestion-btn"
                        >
                            Faire une suggestion libre
                        </HBtn>
                    </template>
                    <template #tooltip>Le référent ne souhaite plus être contacté.</template>
                </ConditionalTooltip>
            </div>
            <img
                v-if="$breakpoint.mdAndUp"
                class="shopping-bag-hearts"
                src="@/assets/images/ehpad/shopping-bag-hearts.svg"
            />
        </HCard>

        <AlgoliaInstantSearch
            v-if="loaded"
            class="global"
        >
            <AlgoliaRefinementListMask
                attribute="institutions"
            />
            <AlgoliaCategoryListMask />

            <div class="search-header">
                <AlgoliaAutocomplete
                    class="search-box"
                    placeholder="Rechercher un produit ou service"
                />
                <AlgoliaSortBy
                    class="sort-by"
                    label="Trier par"
                    :sortItems="sortItems"
                />
            </div>

            <div class="content">
                <div
                    v-show="(isMobile && filterOpened) || !isMobile"
                    class="filter-modal"
                    :class="{ 'filter-modal-open': filterOpened }"
                >
                    <HappydomCategoryList
                        v-if="!isMobile"
                        :categoryId="selectedCategoryId"
                        label="Sous-catégories"
                    />
                    <div class="modal-header">
                        <HBtn
                            class="back-btn"
                            icon="ArrowLeftIcon"
                            icon-only
                            style-type="tertiary"
                            @click="onFilterCloseBtnClick"
                        ></HBtn>
                        <div class="title">Filtres</div>
                    </div>
                    <div class="modal-content">
                        <div class="filters">
                            <div class="filter-header">Filtres</div>
                            <AlgoliaCheckboxList
                                class="filter"
                                attribute="brandLabel"
                                placeholder="Rechercher une marque"
                                label="Marques"
                            />
                            <AlgoliaToggleList
                                class="filter"
                                attribute="size"
                                label="Tailles"
                            />
                            <AlgoliaColorList
                                class="filter"
                                attribute="color"
                                label="Couleurs"
                            />
                            <AlgoliaRange
                                class="filter prices"
                                attribute="price"
                                label="Prix"
                            />
                        </div>
                    </div>
                    <AlgoliaResetBtn
                        v-if="!isMobile"
                        class="reset-filters-btn"
                        :excluded-attributes="['categories.level0', 'institutions']"
                        label="Réinitialiser les filtres"
                    />
                    <div
                        v-show="filterOpened"
                        class="modal-footer"
                    >
                        <div class="action-bar">
                            <AlgoliaResetBtn
                                v-if="isMobile"
                                class="mobile-reset-btn"
                                label="Réinitialiser"
                                :excluded-attributes="['categories.level0', 'institutions']"
                                :customized="true"
                            />
                            <HBtn
                                class="apply-btn"
                                style-type="secondary"
                                @click="onApplyBtnClick"
                            >Appliquer</HBtn>
                        </div>
                    </div>
                </div>
                <div class="results">
                    <AlgoliaStats />
                    <ais-hits
                        :transform-items="transformItems"
                    >
                        <template v-slot="{ items }">
                            <div
                                v-if="items.length < 1"
                                class="no-results"
                            >
                                Nous sommes désolés, il n’y a pas de résultat pour votre recherche.
                            </div>
                            <HRow :gap="3">
                                <HCol
                                    v-for="product in items"
                                    :key="product.objectID"
                                    :cols="12"
                                    :colsSm="6"
                                    :colsMd="4"
                                    :colsLg="3"
                                >
                                    <ProductCard
                                        :product="product"
                                        :displayPcp="isProductIsInPcp(product)"
                                    />
                                </HCol>
                            </HRow>
                            <div
                                v-show="isMobile && !filterOpened"
                                class="filter-bar"
                            >
                                <HBtn
                                    class="filter-btn"
                                    icon="ChevronUpIcon"
                                    rounded
                                    @click="onFilterOpenBtnClick"
                                >Filtres</HBtn>
                            </div>
                         </template>
                    </ais-hits>
                    <AlgoliaPagination
                        @change="onPageChange"
                    />
                </div>
            </div>
        </AlgoliaInstantSearch>

        <HDialog
            v-model="suggestionFormOpen"
            width="800"
            @input="closeSuggestionDialog"
        >
            <template #title>
                Suggestion pour {{ getResidentNameLabel() }}
            </template>
            <SuggestionCreationForm
                ref="suggestionCreationForm"
                @create="createNewOpenSugggestion"
            />
        </HDialog>

        <SpeedDialRefund
            v-if="$_canUserViewPromotional && !!$_residentPromotionalElligibleType"
            :promotional-type="$_residentPromotionalElligibleType"
        />

        <BtnScrollToTop />

        <SuggestionWarningPopin
            v-model="suggestionWarningOpen"
            @profile="goToResidentProfile()"
        />
    </div>
</template>

<script>
import _ from 'lodash'
import async from 'async'
import {
    AisHits
} from 'vue-instantsearch'
import Oauth from "@/oauth"
import Http from '@/http'
import appsettings from '@/appsettings'

import Routes from '@/constants/routes'

import catalogMixins from './catalog.mixins'
import bannersMixin from '@/mixins/bannersMixin'
import residentPromotional from '@/mixins/residentPromotional'

import ResidentHeader from '@/views/residents/components/ResidentHeader'

import { HCard, HBtn, HDialog, HRow, HCol, HSelect } from '@happytal/bo-ui-library'
import SuggestionCreationForm from '@/components/SuggestionCreationForm'
import SuggestionWarningPopin from '@/components/popins/SuggestionWarningPopin'
import SpeedDialRefund from '@/views/residents/components/SpeedDialRefund'
import BtnScrollToTop from '@/components/BtnScrollToTop'
import SliderBanners from '@/components/banners/SliderBanners'

import CatalogItemHeader from './components/CatalogItemHeader'
import ProductCard from './components/ProductCard'
import AlgoliaInstantSearch from './components/AlgoliaInstantSearch'
import AlgoliaRefinementListMask from './components/AlgoliaRefinementListMask'
import AlgoliaCategoryListMask from './components/AlgoliaCategoryListMask'
import AlgoliaCheckboxList from './components/AlgoliaCheckboxList'
import AlgoliaToggleList from './components/AlgoliaToggleList'
import AlgoliaColorList from './components/AlgoliaColorList'
import AlgoliaRange from './components/AlgoliaRange'
import AlgoliaPagination from './components/AlgoliaPagination'
import HappydomCategoryList from './components/HappydomCategoryList'
import AlgoliaAutocomplete from './components/AlgoliaAutocomplete'
import AlgoliaResetBtn from './components/AlgoliaResetBtn'
import AlgoliaSortBy from './components/AlgoliaSortBy'
import AlgoliaStats from './components/AlgoliaStats'
import ConditionalTooltip from '@/components/ConditionalTooltip'

const vueAppAlgoliaSortItems = appsettings.VUE_APP_ALGOLIA_SORT_ITEMS

export default {
    mixins: [
        catalogMixins,
        residentPromotional,
        bannersMixin
    ],
    props: [
        'resident_id',
        'category_id'
    ],
    components: {
        SuggestionCreationForm,
        SuggestionWarningPopin,
        SpeedDialRefund,
        ResidentHeader,
        CatalogItemHeader,
        BtnScrollToTop,
        SliderBanners,
        ConditionalTooltip,

        HRow,
        HCol,
        HCard,
        HBtn,
        HDialog,
        HSelect,

        AisHits,

        AlgoliaInstantSearch,
        AlgoliaRefinementListMask,
        AlgoliaCategoryListMask,
        AlgoliaCheckboxList,
        AlgoliaToggleList,
        AlgoliaColorList,
        AlgoliaRange,
        AlgoliaPagination,
        AlgoliaAutocomplete,
        AlgoliaResetBtn,
        AlgoliaSortBy,
        AlgoliaStats,

        HappydomCategoryList,
        ProductCard
    },
    data () {
        return {
            loaded: false,
            suggestionFormOpen: false,
            suggestionWarningOpen: false,
            sortItems: JSON.parse(vueAppAlgoliaSortItems),
            banners: [],
            filterOpened: false,
            selectedCategoryId: null,
            resident: null
        }
    },
    computed: {
        isEditionAllowed () {
            return this.$aclChecker.some(this.$acl, [
                'isTutor',
                'isFamily',
                'isHappytalSupport',
                'isHappytalEmployee'
            ])
        },
        canEmitSuggestions () {
            return this.$aclChecker.some(this.$acl, [
                'canEmitSuggestions',
            ])
        },
        canDisplayBanners() {
            const isUserCanSeeBanners = this.$aclChecker.some(this.$acl, [
                'isFamily',
                'isTutor',
                'isHappytalSupport'
            ])
            return isUserCanSeeBanners && Boolean(this.banners.length)
        },
        isMobile () {
            return this.$breakpoint.smAndDown
        },
        breadcrumbItems () {
            const items = [
                {
                    type: 'catalog',
                    label: 'Catalogue',
                    route: {
                        name: Routes.ResidentCatalog
                    },
                    iconForMobile: 'BuildingStoreIcon'
                }
            ]
            if (this.selectedCategoryId) {
                const pathCategories = this.getCategoryPathById(this.selectedCategoryId)
                const categoryCount = _.get(pathCategories, 'length', 0)
                if (categoryCount > 0) {
                    pathCategories.forEach((category, index) => {
                        const item = {
                            type: 'category',
                            label: category.name
                        }
                        if (index < categoryCount - 1) {
                            item.route = {
                                name: Routes.ResidentCatalog,
                                params: {
                                    category_id: category.id
                                }
                            }
                        }
                        items.push(item)
                    })
                }
            }
            else {
                items.push({
                    type: 'category',
                    label: `Tous les produits`
                })
            }
            return items
        }
    },
    methods: {
        getUserId () {
            return this.$store.getters['dataUser/getUserId']()
        },
        getInstitutionId () {
            return this.$store.getters['dataUser/getInstitutionId']()
        },
        getCatalogCategoryById (categoryId) {
            return this.$store.getters['dataCatalogCategories/getCategoryById'](categoryId)
        },
        getCategoryPathById (categoryId) {
            return this.$store.getters['dataCatalogCategories/getCategoryPathById'](categoryId)
        },
        getResidentPcp (residentId) {
            return this.$store.getters['dataPcps/getResidentPcp'](residentId)
        },
        getCurrentPcp () {
            return this.getResidentPcp(this.resident_id)
        },
        getResidentNameLabel () {
            return this.$store.getters['dataResidents/getResidentName'](this.resident_id)
        },
        isProductIsInPcp (product) {
            const pcp = this.getCurrentPcp()
            if (!pcp) {
                return false
            }
            let result = false
            switch (product.type) {
                case 'Product':
                    result = _.find(pcp.shoppingListProducts, (slp) => {
                        const variantCode = _.get(slp, 'meta.product.data.variantCode', null)
                        if (variantCode) {
                            return variantCode == _.get(product, 'variantCode', null)
                        }
                        else {
                            return _.get(slp, 'meta.product.productSku') == product.code
                        }
                    })
                    break
                case 'Service':
                    result = _.find(pcp.shoppingListServices, (slp) => {
                        return _.get(slp, 'meta.serviceModel.code') == product.code
                    })
                    break
            }
            return Boolean(result)
        },
        transformItems (items) {
            return items.map((item) => {
                let route = {
                    params: {
                        resident_id: this.resident_id
                    }
                }
                let code = null
                switch (item.type) {
                    case 'Product':
                        code = item.productSku
                        route.params.product_id = item.productSku
                        if (item.variantCode) {
                            route.name = Routes.CatalogVariant
                            route.params.variant_id = item.variantCode
                        }
                        else {
                            route.name = Routes.CatalogProduct
                        }
                        break
                    case 'Service':
                        code = item.serviceModelCode
                        route.name = Routes.CatalogService
                        route.params.service_model_id = item.serviceModelCode
                        break
                }
                return {
                    id: item.objectID,
                    code,
                    variantCode: item.variantCode,
                    title: item.title,
                    imageUrl: item.image,
                    brand: item.brandLabel,
                    price: item.price,
                    quantity: item.formFactor,
                    route,
                    type: item.type
                }
            })
        },
        onFilterOpenBtnClick (e) {
            this.filterOpened = true
        },
        onFilterCloseBtnClick (e) {
            this.filterOpened = false
        },
        onApplyBtnClick (e) {
            this.filterOpened = false
        },
        onPageChange () {
            this.scrollToTop()
        },
        updateDraftPopup () {
            setTimeout(() => {
                    this.$store.commit('dataDrafts/SET_POPUP_VISIBLE', {
                        visible: false
                    })
                },
                4000
            )
        },
        showInfoToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration: 5000,
                action: [
                    {
                        text: 'Fermer',
                        onClick: (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        },
        closeSuggestionDialog () {
            this.suggestionFormOpen = false
            this.$refs.suggestionCreationForm.reset()
        },
        async createNewOpenSugggestion (shortDescription, description, creatorName, continueAfterCreation) {
            await this.$store.dispatch('dataSuggestions/createOpenSuggestion', {
                residentId: this.resident_id,
                creatorName,
                institutionId: this.getInstitutionId(),
                shortDescription,
                description,
            })
            if (continueAfterCreation) {
                this.$refs.suggestionCreationForm.reset()
            }
            else {
                this.closeSuggestionDialog()
            }
            this.showInfoToast('La suggestion a bien été envoyée')
        },
        getResidentSuggestionLabel () {
            return this.isSuggestionDisabled() ? `Le référent ne souhaite plus être contacté` : null
        },
        isSuggestionDisabled () {
            return !_.get(this.resident, 'enableSuggestion', false)
        },
        getResidentTutor (resident) {
            return _.get(resident, 'userInChargeOfResident', null)
        },
        isResidentHasTutor (resident) {
            return this.getResidentTutor(resident) != null
        },
        showSuggestionWarning (visible) {
            this.suggestionWarningOpen = visible
        },
        isEhpadUser () {
            return this.$aclChecker.some(this.$acl, [
                'isEhpadEmployee',
                'isEhpadManager'
            ])
        },
        onAddSuggestionBtnClick (e) {
            if (this.isEhpadUser() && !this.isResidentHasTutor(this.$_resident)) {
                this.showSuggestionWarning(true)
                return
            }
            this.suggestionFormOpen = true
        },
        goToResidentProfile() {
            this.$router.push({
                name: Routes.ResidentProfile,
                params: {
                    resident_id: this.$_resident.id,
                }
            })
        },
        scrollToTop () {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }
    },
    async mounted () {
        try {
            this.scrollToTop()

            if (!this.$store.state.dataResidents.isRegistered) {
                await this.$store.dispatch('dataResidents/getResidents', {
                    userId: this.getUserId(),
                    institutionId: this.getInstitutionId()
                })
            }

            await this.$store.dispatch('dataCatalogCategories/getCategories', {
                institutionId: this.getInstitutionId()
            })

            this.selectedCategoryId = this.category_id || null
            console.log('selectedCategoryId', this.selectedCategoryId)

            const bannersApiPath = `/banners/commercials?institutionsBannerCatalog.institution_id=0&institutionsBannerCatalog.institution_id=${this.getInstitutionId()}`
            const bannersResponse = await Http.apis.strapi.get(bannersApiPath)
            this.banners = this.$_checkBanners(bannersResponse.data[0])

            await this.$store.dispatch('dataPcps/getResidentPcp', {
                residentId: this.resident_id
            })

            this.resident = this.getResidentById(this.resident_id)
            Oauth.checkUserRight(this.resident, this.$router, this.$vs.loading)
            this.updateDraftPopup()

            this.loaded = true
        }
        catch (error) {
            console.error(error)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';

    .coordination-card {
        position: relative;
        overflow: hidden;

        .choice-text {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 1.5;
            text-align: center;
        }

        .shopping-bag-hearts {
            position: absolute;
            bottom: 0;
            right: 8px;
        }

        .add-suggestion {
            width: 320px;
        }
    }
    .global {

        .search-header {
            display: flex;
            align-items: center;
            margin: 5px 0px 0px 0px;

            @media (max-width: map-get($breakpoints, sm)) {
                flex-wrap: wrap;
            }

            .search-box {
                flex-grow: 1;

                ::v-deep .aa-Autocomplete {
                    width: 100%;
                }
            }
            .sort-by {
                margin: 0px 0px 0px 30px;
                z-index: 2;
            }
        }
        .reset-btn {

        }
        .content {
            display: flex;

            .filter-modal {
                width: 260px;

                .reset-filters-btn {
                    border-top: 1px solid #EEEEEE;
                }
                .modal-header {
                    display: none;

                    .title {

                    }
                }
                .modal-content {

                    .filters {
                        width: 260px;
                        flex-shrink: 0;

                        .filter-header {
                            padding: 20px 0px 20px 0px;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 20px;
                            color: #000000;
                        }
                        .filter {

                        }
                    }
                }
                .modal-footer {

                    .action-bar {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 54px;
                        background-color: var(--v-primary-base);

                        .reset-btn {
                            border: 2px solid white;
                        }
                        .apply-btn {
                            margin: 0px 0px 0px 20px;
                            border: 2px solid white;
                            background: white;
                        }
                    }
                }
            }
            .filter-modal-open {
                display: flex;
                flex-direction: column;
                position: fixed;
                left: 0px;
                top: 0px;
                right: 0px;
                bottom: 0px;
                z-index: 15;
                width: auto;

                .modal-header {
                    display: flex;
                    align-items: center;
                    flex-shrink: 0;
                    height: 54px;
                    background: white;
                    padding: 0px 20px 0px 20px;
                    border-bottom: 1px solid #EEEEEE;
                    box-shadow: 0 0 8px 0 rgba(0,0,0,.1);

                    .title {
                        margin: 0px 0px 0px 15px;
                        font-weight: 600;
                        font-size: 17px;
                        line-height: 20px;
                        text-transform: uppercase;
                        color: black;
                    }
                }
                .modal-content {
                    height: calc(100% - 108px);
                    overflow-y: auto;
                    background: white;

                    .filters {
                        flex-grow: 1;
                        width: auto;

                        @media (max-width: map-get($breakpoints, sm)) {
                            padding: 0px 0px 20px 0px;
                        }

                        .filter-header {
                            display: none;
                        }
                        .filter {
                            padding: 0px 20px 0px 20px;
                        }
                    }
                }
                .modal-footer {
                    flex-shrink: 0;
                    height: 54px;
                }
            }
            .results {
                flex-grow: 1;
                margin: 20px 0px 20px 40px;

                @media (max-width: map-get($breakpoints, sm)) {
                    margin: 20px 0px 20px 0px;
                }

                .products {

                    .no-results {
                        margin: 30px 0px 30px 0px;
                        font-weight: 500;
                        font-size: 17px;
                        line-height: 20px;
                        text-align: center;
                        color: black;
                    }
                }
                .filter-bar {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 54px;
                    position: fixed;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    z-index: 2;

                    .filter-btn {

                    }
                }
                .pagination {

                }
            }
        }
    }
</style>
