<template>
    <div class="happydom-category-list">
        <div
            v-if="items.length > 0"
            class="header"
        >
            <div class="title">{{ label }}</div>
        </div>
        <div
            v-if="items.length > 0"
            class="categories"
        >
            <h-menu-item
                v-for="item in items"
                :key="item.id"
                :title="item.name"
                @click="onCategoryClick(item)"
            />
        </div>
    </div>
</template>

<script>
import _ from 'lodash'

import Routes from '@/constants/routes'

import {
    HMenuItem
} from '@happytal/bo-ui-library'

export default {
    name: 'HappydomCategoryList',
    props: {
        categoryId: {
            default: null,
            required: true
        },
        label: {
            type: String,
            default: null
        }
    },
    components: {
        HMenuItem
    },
    computed: {
        items () {
            if (!this.categoryId) {
                return []
            }
            const category = this.$store.getters['dataCatalogCategories/getCategoryById'](this.categoryId)
            //console.log('category', category)
            return _.get(category, 'subCategories', [])
        }
    },
    methods: {
        onCategoryClick (item) {
            this.$router.push({
                name: Routes.ResidentCatalog,
                params: {
                    category_id: item.id
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';
.happydom-category-list {

    .header {
        display: flex;
        align-items: center;
        padding: 20px 0px 0px 0px;
        border-top: 1px solid #EEEEEE;

        .title {
            flex-grow: 1;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: black;
        }
    }
    .categories {
        padding: 20px 0px 20px 0px;

        .category-item {
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #2C2C2C;
            cursor: pointer;
        }
    }
}
</style>
